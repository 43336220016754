import React from 'react'
import styled from 'styled-components'
import { Layout, Center } from '../layout'
import Seo from '../seo'
import { JapanesePolicy } from '../policy-japanese'
import { EnglishPolicy } from '../policy-english'
import { Location } from '../../interfaces/gatsby'
import { SiteMetadata } from '../../interfaces/siteMetadata'

interface Props {
  en: boolean
  location: Location
  siteMetadata: SiteMetadata
}

export const PrivacyPolicy = ({ en, location, siteMetadata }: Props) => {
  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata}>
      <Seo lang={en ? 'en' : 'ja'} />
      <Center>
        <StyledDiv>{en ? <EnglishPolicy /> : <JapanesePolicy />}</StyledDiv>
      </Center>
    </Layout>
  )
}

const StyledDiv = styled.div`
  padding: 0 10vw;
  padding-bottom: 130px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 0 5vw;
    margin-top: -20px;
    padding-bottom: 100px;
  }

  h2 {
    font-size: ${({ theme }) => theme.font.h3};
    margin: 70px 0 20px 0;
  }

  div {
    font-size: 0.95rem;
    line-height: 1.5rem;
  }

  a {
    color: ${({ theme }) => theme.accentMagenta};
  }
`
